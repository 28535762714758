import '@/MuiClassNameSetup'

// eslint-disable-next-line @typescript-eslint/no-floating-promises
Promise.all([import('@/Root'), import('@/Routes')]).then(
  ([{ default: render }, { default: AppRoutes }]) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    render(AppRoutes)
  }
)

export {}
